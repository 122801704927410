import React from 'react';
import { MainData } from '../Home';
import { FaDownload } from 'react-icons/fa';

type Props = {
  data?: MainData
}

export default function About({data}: Props) {

  if (!data) return null;

  let name = data.name;
  let profilepic = 'images/' + data.image;
  let bio = data.bio;
  let street = data.address.street;
  let city = data.address.city;
  let state = data.address.state;
  let zip = data.address.zip;
  let phone= data.phone;
  let email = data.email;
  let resumeDownload = data.resumedownload;

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic"  src={profilepic} alt="Profile Pic" />
        </div>
        <div className="nine columns main-col">
          <h2>About Me</h2>
          <p>{bio}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{name}</span><br />
                <span>{street} {street && <br />}
                  {city}, {state} {zip}
                </span><br />
                {phone && <React.Fragment><span>{phone}</span><br /></React.Fragment>}
                <span>{email}</span>
              </p>
            </div>
            <div className="columns download">
              <p>
                {resumeDownload &&
                  <a href={resumeDownload} className="button"><FaDownload />Download Resume</a>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
