import React from 'react';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';

export default function SocialIcon({name, ...props}: {name: string}) {
    let IconComponent: any;
    switch(name.toLowerCase()){
        case 'github':
            IconComponent = FaGithub;
            break;
        case 'linkedin':
            IconComponent = FaLinkedinIn;
            break;
        default:
            return null;
    }
    return <IconComponent style={{display: 'inline-block'}} {...props} />
}
