import React from 'react';

//import { Typography, Divider, Avatar } from '@material-ui/core';
import { WorkOutline as WorkIcon } from '@material-ui/icons';
//import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import DatedBulletedContent from './DatedBulletedContent';
import { DatedBulletedData } from '../../../types';

/*
const useStyles = makeStyles(() => ({
  
}));
*/

type Props = {
  workplaces?: Array<DatedBulletedData>;
}

export default function Experience({workplaces=[]}: Props) {
  return(
    <Section icon={WorkIcon} title='Experience'>
      <DatedBulletedContent info={workplaces} />
    </Section>
  )
}
