import React from 'react';

import { Typography, Divider, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingBottom: 5
  },
  avatar: {
    marginRight: 10,
    marginLeft: 5,
    height: 25,
    width: 25,
    backgroundColor: theme.palette.grey[600]
  },
  content: {
    marginBottom: 15,
    marginTop: 5
  }
}));

type Props = {
  icon: any;
  title: string;
  children: any;
}

export default function Section({icon: IconComponent, title, children}: Props) {
  const classes = useStyles();
  return(
    <div>
      <div className={classes.header}>
        <Avatar className={classes.avatar}><IconComponent fontSize='0.9em'/></Avatar>
        <Typography variant='h5' >{title}</Typography>
      </div>
      <Divider />
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}
