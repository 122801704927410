import React from 'react';

import { FaTerminal } from 'react-icons/fa';
import Section from './Section';
import ProficiencySection from './ProficiencySection';
import { SkillData } from '../../../types';

type Props = {
  tools?: Array<SkillData>
}

export default function DevelopmentTools({tools}: Props) {
  if (!tools || tools.length === 0) return null;

  return(
    <Section icon={FaTerminal} title='Tools'>
      {tools.map(l => (
        <ProficiencySection key={l.name} text={l.name} level={l.level} />
      ))}
    </Section>
  )
}
