import React from 'react';
import { FiAward } from 'react-icons/fi';
import Section from './Section';
import { Typography } from '@material-ui/core';

type Props = {
  data?: Array<string>;
}

export default function Achievements({data=[]}: Props) {
  return(
    <Section icon={FiAward} title='Achievements'>
      {data.map((s: string) => (
        <Typography key={s} style={{marginBottom: 5}} variant='body1'>{s}</Typography>
      ))}
    </Section>
  )
}
