import React, { useContext } from 'react';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintContext from '../../../components/PrintContext';
import { DatedBulletedData } from '../../../types';

const useStyles = makeStyles(() => ({
  bullet: {
    display: 'inline-flex', 
    alignItems: 'top', 
    marginBottom: 2
  }
}));

type Props = {
  info?: Array<DatedBulletedData>;
}

export default function DatedBulletedContent({info=[]}: Props) {
  const classes = useStyles();
  const isForPrint = useContext(PrintContext);
  return(
    <Grid container>
      {info.map((s, i) => (
        <React.Fragment key={i}>
          <Grid item xs={isForPrint ? 2 : 12} sm={2}>
            {s.fromDate === null ? 
              <Typography variant='subtitle2'>{s.toDate}</Typography>
              : <Typography variant='subtitle2'>{s.fromDate} - {s.toDate}</Typography>}
          </Grid>
          <Grid item xs={isForPrint ? 10 : 12} sm={10}>
            <div style={{paddingLeft: 20}}>
              <Typography variant='subtitle2'>{s.title}</Typography>
              <Typography variant='subtitle2'>{s.place}</Typography>
              {s.bullets.map((b, i) => (
                <div key={i} className={classes.bullet}>
                  <Typography variant='body2'>{'\u25cf'}&nbsp;&nbsp;&nbsp;</Typography>
                  <Typography variant='body2'>{b}</Typography>
                </div>
              ))}
            </div>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}
