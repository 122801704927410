import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SkillIcon from '../../../components/SkillIcon';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 3,
    display: 'inline-flex', 
    width: '100%', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  text: {
    display: 'inline-flex', 
    alignItems: 'center'
  },
  icon: {
    color: '#444'
  }
}));

type Props = {
  text: string,
  level: number,
  showLevel?: boolean
}

export default function ProficiencySection({text, level, showLevel=false}: Props) {
  const classes = useStyles();
  /*let levelWords = useMemo(() => {
    switch(level) {
      case 1:
        return 'Basic';
      case 2:
        return 'IDK';
      case 3:
        return 'Intermediate';
      case 4:
        return 'Very Good';
      case 5:
        return 'Excellent';
      default:
        return 'Very Bad';
    }
  }, [level]);*/
  let levelText = '\u25cf'.repeat(level) + '\u25cb'.repeat(5 - level);
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <SkillIcon skillName={text} className={classes.icon}/>
        <Typography variant='body1'>{text}</Typography>
      </div>
      {showLevel && <div>
        <Typography variant='h4'>{levelText}</Typography>
        {/*<Typography variant='body1'>{levelWords}</Typography>*/}
      </div>}
    </div>
  )
}
