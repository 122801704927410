import React from 'react';

//import { Typography, Divider, Avatar } from '@material-ui/core';
//import { WorkOutline as WorkIcon } from '@material-ui/icons';
import { FiSettings } from 'react-icons/fi';
//import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import { Typography } from '@material-ui/core';
/*
const useStyles = makeStyles(() => ({
  
}));
*/
type Props = {
  skills?: Array<string>
}

export default function Skills({skills=[]}: Props) {
  return(
    <Section icon={FiSettings} title='Skills'>
      {skills.map(s => (
        <Typography key={s} style={{marginBottom: 5}} variant='body1'>{s}</Typography>
      ))}
    </Section>
  )
}
