import React from 'react';

import { PersonOutline as PersonIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import { Typography } from '@material-ui/core';
import { PersonalInfoData } from '../../../types';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary,
    '&:visited': {
      color: 'inherit'
    },
    '&:hover': {
      color: 'inherit'
    }
  }
}));

type FieldProps = {
  title: string,
  value: string,
  href?: string
}

function Field({title, value, href}: FieldProps) {
  const classes = useStyles();
  return (
    <div style={{marginBottom: 5}}>
      <Typography variant='subtitle1'>{title}</Typography>
      {href ? 
        <Typography variant='body1'><a href={href} className={classes.link}>{value}</a></Typography>
      : <Typography variant='body1'>{value}</Typography>}
    </div>
  );
}

type Props = {
  info: PersonalInfoData
}

export default function PersonalInfo({info}: Props) {
  return(
    <Section icon={PersonIcon} title='Personal Info'>
      <Field title='Address' value={info.address} />
      <Field title='Phone' value={info.phone} />
      <Field title='E-mail' value={info.email} href={`mailto:${info.email}`}/>
      <Field title='LinkedIn' value={info.linkedIn} href={`https://${info.linkedIn}`}/>
      {info.website && <Field title='Website' value={info.website} href={`https://${info.website}`}/>}
    </Section>
  )
}
