import React, { Component } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Portfolio from './Components/Portfolio';


export interface MainData {
  name: string;
  title: string;
  description: string;
  linkedin: string;
  github: string;
  image: string;
  bio: string;
  address: {
    street: string,
    city: string,
    state: string,
    zip: string
  };
  phone: string;
  email: string;
  resumedownload: string;
  contactmessage: string;
  social: Array<{
    name: string,
    url: string
  }>
}

export interface WorkData {
  description: string | Array<string>;
  company: string;
  title: string;
  years: string;
}

interface SkillData {
  name: string;
  level: number;
}

interface EducationData {
  school: string;
  degree: string;
  graduated: string;
  description: string;
  gpa: string;
}

export interface ResumeData {
  work: Array<WorkData>;
  skills: Array<SkillData>;
  education: Array<EducationData>;
  skillmessage: string;
}

interface ProjectData {
  title: string;
  url: string;
  category: string;
  image: string;
}

export interface PortfolioData {
  projects: Array<ProjectData>
}

interface HomeData {
  main?: MainData,
  resume?: ResumeData,
  portfolio?: PortfolioData
}

type State = {
  resumeData: HomeData
}

class App extends Component<{}, State> {

  state: Readonly<State> = {
    resumeData: {}
  };

  getResumeData(){
    fetch('./resumeData.json')
      .then(response => response.json())
      .then(data => this.setState({resumeData: data}))
      .catch(() => console.log('A fetching error occurred'));
  }

  componentDidMount(){
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.main}/>
        <About data={this.state.resumeData.main}/>
        <Resume data={this.state.resumeData.resume}/>
        <Portfolio data={this.state.resumeData.portfolio}/>
        
        <Footer data={this.state.resumeData.main}/>
      </div>
    );
  }
}

export default App;
