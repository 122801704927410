import React from 'react';

import { Typography } from '@material-ui/core';
import { HeadingData } from '../../../types';

type Props = {
  info: HeadingData
}

export default function Heading({info}: Props) {
  return(
    <React.Fragment>
      <Typography variant='h3'>{info.name}</Typography>
      {info.includeTitle && <Typography style={{marginBottom: 5}} variant='h5'>{info.title}</Typography>}
      <Typography style={{marginBottom: 15}} variant='body1'>{info.objective}</Typography>
    </React.Fragment>
  )
}
