import React, { useEffect, useState } from 'react';

import { Container, Paper } from '@material-ui/core';

import FullResume from './FullResume';

export default function MyResume() {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    fetch('/fullResumeData.json')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  return(
    <div>
      <Container maxWidth='md'>
        <Paper style={{paddingTop: 1}}>
          <FullResume data={data}/>
        </Paper>
      </Container>
    </div>
  );
}
