import React from 'react';

import { FaCode } from 'react-icons/fa';
import Section from './Section';
import ProficiencySection from './ProficiencySection';
import { SkillData } from '../../../types';

type Props = {
  languages?: Array<SkillData>
}

export default function ProgrammingLanguages({languages}: Props) {
  if (!languages || languages.length === 0) return null;

  return(
    <Section icon={FaCode} title='Languages'>
      {languages.map(l => (
        <ProficiencySection key={l.name} text={l.name} level={l.level} />
      ))}
    </Section>
  )
}
