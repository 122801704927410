import React from 'react';
import Heading from './Components/Heading';
import PersonalInfo from './Components/PersonalInfo';
import Experience from './Components/Experience';
import Education from './Components/Education';

import { Grid } from '@material-ui/core';
import Skills from './Components/Skills';
import ProgrammingLanguages from './Components/ProgrammingLanguages';
import Certifications from './Components/Certifications';
import DevelopmentTools from './Components/DevelopmentTools';
import PrintContext from '../../components/PrintContext';
import Achievements from './Components/Achievements';

interface FullResumeData {
  headerInfo: any;
  personalInfo: any;
  skills: any;
  programmingLanguages: any;
  achievements: any;
  education: any;
  experience: any;
  developmentTools: any;
  certifications: any;
}

type Props = {
  data?: FullResumeData | null;
}

export default class FullResume extends React.Component<Props> {
  render() {
    const { data } = this.props;
    const isForPrint = PrintContext.Consumer;
    if (!data) return null;
    return (
      <div style={{margin: 25}}>
        <Heading info={data.headerInfo} />
        <Grid container spacing={2}>
          <Grid item xs={isForPrint ? 3 : 12} sm={3}>
            <PersonalInfo info={data.personalInfo}/>
            <Skills skills={data.skills} />
            <ProgrammingLanguages languages={data.programmingLanguages} />
            <DevelopmentTools tools={data.developmentTools}/>
            {data.achievements.include && <Achievements data={data.achievements.content} />}
          </Grid>
          <Grid item xs={isForPrint ? 9 : 12} sm={9}>
            <Education schools={data.education}/>
            <Experience workplaces={data.experience}/>
            {data.certifications.include && <Certifications certs={data.certifications.content}/>}
          </Grid>
        </Grid>
      </div>
    )
  }
}
