import React from 'react';
import { MainData } from '../Home';
import { SocialIcon } from '../../../components';
import { Link } from 'react-scroll';

type Props = {
  data?: MainData
}

export default function Footer({data}: Props) {
  if (!data) return null;
  
  let networks= data.social.map(function(network){
    return <li key={network.name}><a href={network.url}><SocialIcon name={network.name} /></a></li>
  });

  return (
    <footer>

      <div className="row">
        <div className="twelve columns">
            <ul className="social-links">
              {networks}
            </ul>

            <ul className="copyright">
              <li>&copy; Copyright {Math.max(2020, new Date().getFullYear())} Justin Chilton</li>
            </ul>

        </div>
        <div id="go-top">
          <Link activeClass='current' className='smoothscroll' to='home' href='#home' spy={true} smooth={true} duration={500} title="Back to Top"><i className="icon-up-open"></i></Link>
        </div>
      </div>
    </footer>
  );
}
