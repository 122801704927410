import React, { Component } from 'react';

import { MdWork, MdSchool } from 'react-icons/md';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SkillIcon from '../../../components/SkillIcon';

import seedrandom from 'seedrandom';
import { WorkData, ResumeData } from '../Home';

type WorkProps = {
  data: WorkData
};

function Work({data}: WorkProps) {
  let description = typeof data.description === 'string' ? [data.description] : data.description;
  return (
    <VerticalTimelineElement  icon={<MdWork />}
      className="vertical-timeline-element--work"
      contentArrowStyle={{ borderRight: '7px solid  #3973AC' }}
      iconStyle={{ background: '#3973AC', color: '#fff' }}

    >
      <div key={data.company}><h3>{data.company}</h3>
          <p className="info">{data.title}</p>
          <p><em className="date">{data.years}</em></p>
          {description.map((str, i) => <p key={i} style={{fontSize: '1.25rem'}}>{str}</p>)}
      </div>
    </VerticalTimelineElement>
  );
}

type ResumeProps = {
  data?: ResumeData
}

class Resume extends Component<ResumeProps> {

  getRandomColor(data: any) {
    let letters = '0123456789AB';
    let color = '#';
    let rng = seedrandom(data);
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(rng() * 10)];
    }
    return color;
  }
  
  render() {
    let education = null;
    let work = null;
    let skillmessage = null;
    let skills = null;
    if(this.props.data){
      skillmessage = this.props.data.skillmessage;
      education = (
        <VerticalTimeline layout='1-column'>
          
          {this.props.data.education.map(function(education){
            return (
              <VerticalTimelineElement key={education.school} 
                icon={<MdSchool />}
                className="vertical-timeline-element--education"
                contentArrowStyle={{ borderRight: '7px solid #AC393A' }}
                iconStyle={{ background: '#AC393A', color: '#fff' }}

                > 
                  <div key={education.school}><h3>{education.school}</h3>
                    <p className="info">{education.degree}</p>
                    <p>
                      <em className="date">{education.graduated}</em>
                      <span>&nbsp; &bull; &nbsp;</span>
                      <em>GPA: {education.gpa}</em>
                    </p>
                    <p style={{fontSize: '1.25rem'}}>{education.description}</p>
                  </div>
              </VerticalTimelineElement>
            )})}
        </VerticalTimeline>
      );
      work = (
        <VerticalTimeline layout='1-column'>
          {this.props.data.work.map((work, i) => <Work key={i} data={work} />)}
        </VerticalTimeline>
      );

      skills = this.props.data.skills.map((skill)=>{
        var className = 'bar-expand '+skill.name.toLowerCase();
        return (
          <li key={skill.name}>
            <span style={{width:skill.level, backgroundColor:this.getRandomColor(skill.name)}}className={className}></span>
              <em>
                <div style={{display: 'inline-flex'}}>
                  <SkillIcon skillName={skill.name} />
                  {skill.name}
                </div>
              </em>
          </li>
        )
      })
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>



      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>{skillmessage}
            </p>

				<div className="bars">
				   <ul className="skills">
					  {skills}
					</ul>
				</div>
			</div>
      </div>
   </section>
    );
  }
}

export default Resume;
