import React from 'react';
import ParticlesBg  from "particles-bg";
import { MainData } from '../Home';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-scroll';

type Props = {
  data?: MainData
}

export default function Header(props: Props) {
  if(!props.data)
    return null;
  
  let linkedin = props.data.linkedin;
  let github = props.data.github;
  let title = props.data.title;
  let description= props.data.description;

  return (
    <header id="home">
      <ParticlesBg type='cobweb' bg={true} color='#3973ac'/> {/* '#ac393a' */}
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
        <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

        <ul id="nav" className="nav">
            <li><img src="/favicon.ico" alt='JC' style={{width: '20px', verticalAlign: 'middle'}} /></li>
            <li><Link activeClass='current' to='home'      href='#home'      hashSpy={true} spy={true} smooth={true} duration={500}>Home</Link></li>
            <li><Link activeClass='current' to='about'     href='#about'     hashSpy={true} spy={true} smooth={true} duration={500}>About</Link></li>
            <li><Link activeClass='current' to='resume'    href='#resume'    hashSpy={true} spy={true} smooth={true} duration={500}>Resume</Link></li>
            <li><Link activeClass='current' to='portfolio' href='#portfolio' hashSpy={true} spy={true} smooth={true} duration={500}>Works</Link></li>
        </ul>
      </nav>

      <div className="row banner">
      
        <div className="banner-text">
            <h1 className="responsive-headline">{title}</h1>
            <h3>{description}</h3>
            <hr />
            <ul className="social">
              <a href={linkedin} className="button btn linkedin-btn"><FaLinkedinIn />LinkedIn</a>
              <a href={github} className="button btn github-btn"><FaGithub />GitHub</a>
            </ul>
        </div>
      </div>

      <p className="scrolldown">
        <Link className='smoothscroll' to='about' href='#about' spy={true} smooth={true} duration={500}><i className="icon-down-circle"></i></Link>
      </p>
    </header>
  );
}
