import React from 'react';
import { Grid } from '@material-ui/core';
import { PortfolioData } from '../Home';

type Props = {
  data?: PortfolioData;
}

export default function Portfolio(props: Props) {
  if (!props.data) return null;
  let projects = props.data.projects.map(function (projects) {
    let projectImage = 'images/portfolio/' + projects.image;
    
    return (
      <Grid key={projects.title} item xs={12} sm={6} md={6} lg={4} xl={4} className="portfolio-item">
        <div className="item-wrap">
          <a href={projects.url} title={projects.title}>
            <img alt={projects.title} src={projectImage} />
            <div className="overlay">
              <div className="portfolio-item-meta">
                <h5>{projects.title}</h5>
                <p>{projects.category}</p>
              </div>
            </div>
          </a>
        </div>
      </Grid>
    )
  });

  return (
    <section id="portfolio">

      <div className="row">

        <div className="twelve columns collapsed">

          <h1>Check Out Some of My Works.</h1>

          <Grid container spacing={2} justify="center" alignItems="center" style={{paddingLeft: '0.5em', paddingRight: '0.5em'}}>
            {projects}
          </Grid>
          
        </div>
      </div>
    </section>
  );
}
