import React from 'react';

import { FaReact, FaCss3, FaJava, FaNodeJs, FaJira } from 'react-icons/fa';
import { DiJavascript1, DiPython, DiVisualstudio, DiGitBranch, DiIntellij, DiWindows, DiApple, DiLinux } from 'react-icons/di';
import Icon from '@mdi/react';
import { mdiLanguageTypescript } from '@mdi/js';
import { FiHexagon, FiSquare, FiDatabase, FiCircle, FiGithub } from 'react-icons/fi';

type Props = {
  skillName: string;
  style?: React.CSSProperties;
  className?: string;
}

export default function SkillIcon({skillName, style, ...props}: Props) {
  let IconComponent: any;
  let mdiIcon = null;
  switch(skillName.toLowerCase()){
    case 'react.js':
      IconComponent = FaReact;
      break;
    case 'css':
      IconComponent = FaCss3;
      break;
    case 'python':
      IconComponent = DiPython;
      break;
    case 'java':
      IconComponent = FaJava;
      break;
    case 'typescript':
      mdiIcon = mdiLanguageTypescript;
      break;
    case 'javascript':
      IconComponent = DiJavascript1;
      break;
    case 'node.js':
      IconComponent = FaNodeJs;
      break;
    case 'sql':
      IconComponent = FiDatabase;
      break;
    case 'express.js':
      IconComponent = FiCircle;
      break;
    case 'c++':
      IconComponent = FiHexagon;
      break;
    case 'jmp scripting language':
    case 'jsl':
      IconComponent = FiSquare;
      break;
    case 'visual studio':
      IconComponent = DiVisualstudio;
      break;
    case 'intellij':
      IconComponent = DiIntellij;
      break;
    case 'git':
      IconComponent = DiGitBranch;
      break;
    case 'github':
      IconComponent = FiGithub;
      break;
    case 'jira':
      IconComponent = FaJira;
      break;
    case 'windows':
      IconComponent = DiWindows;
      break;
    case 'macos':
      IconComponent = DiApple;
      break;
    case 'linux':
      IconComponent = DiLinux;
      break;
    default:
      IconComponent = FiSquare;
  }
  return (
    mdiIcon ?
      <Icon path={mdiIcon} size={1} color='#444' style={{marginRight: '10px', ...style}}/>
    : <IconComponent size='1.25em' style={{marginRight: '10px', ...style}} {...props}/>
  );
}
