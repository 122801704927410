import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

import Home from './pages/Home';

import { ThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
import ResumeGenerator from './pages/ResumeGenerator';
import MyResume from './pages/MyResume';

const theme = createMuiTheme({
  palette: {
    //type: 'dark'
  },
  typography: {
    fontSize: 18,
    h1: {
      fontWeight: 500
    },
    h2: {
      fontWeight: 500
    },
    h3: {
      fontWeight: 600
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 700
    },
    subtitle1: {
      fontWeight: 600
    },
    subtitle2: {
      fontWeight: 600
    }
  }
});

export default function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path='/'>
              <div>
                <CssBaseline />
                <Switch>
                  <Route path="/resume">
                    <MyResume />
                  </Route>
                  <Route path="/resumeGenerator">
                    <ResumeGenerator />
                  </Route>
                  <Route path='/'>
                    <Redirect to='/' />
                  </Route>
                </Switch>
              </div>
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}
