import React from 'react';

//import { Typography, Divider, Avatar } from '@material-ui/core';
import { SchoolOutlined as SchoolIcon } from '@material-ui/icons';
//import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import DatedBulletedContent from './DatedBulletedContent';
import { DatedBulletedData } from '../../../types';
/*
const useStyles = makeStyles(() => ({
  
}));
*/

type Props = {
  schools?: Array<DatedBulletedData>
}

export default function Education({schools=[]}: Props) {
  return(
    <Section icon={SchoolIcon} title='Education'>
      <DatedBulletedContent info={schools} />
    </Section>
  )
}
