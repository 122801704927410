import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  buttonLabel: {
    font: 'inherit', color: 'inherit'
  }
}));

type Props = {
  extension: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
};

export default function FileSelectButton({extension, onChange}: Props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <input 
        accept={extension} 
        className={classes.input}
        id="file" 
        type="file" 
        onChange={onChange}
      /> 
      <label htmlFor="file" > 
        <Button component="span" variant='contained' classes={{label: classes.buttonLabel}}> 
          Import 
        </Button> 
      </label> 
    </React.Fragment>
  )
}
