import React from 'react';

//import { Typography, Divider, Avatar } from '@material-ui/core';
//import { WorkOutline as WorkIcon } from '@material-ui/icons';
import { FiAward } from 'react-icons/fi';
//import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';
import { Grid, Typography } from '@material-ui/core';
import { CertificationData } from '../../../types';
/*
const useStyles = makeStyles(() => ({
  
}));
*/

type Props = {
  certs: Array<CertificationData>
}

export default function Certifications({certs=[]}: Props) {
  if (!certs || certs.length === 0) return null;
  return(
    <Section icon={FiAward} title='Certifications'>
      <Grid container alignItems='center'>
        {certs.map((c, i) => (
          <React.Fragment key={i}>
            <Grid item xs={2}>
              <Typography variant='subtitle2'>{c.date}</Typography>
            </Grid>
            <Grid item xs={10}>
              <div style={{paddingLeft: 20}}>
                <Typography variant='body2'>{c.name}</Typography>
              </div>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Section>
  )
}
