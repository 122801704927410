import React, { useRef, useEffect, useState } from 'react';

import ReactToPrint from 'react-to-print';

import { CssBaseline, Container, Paper, Button } from '@material-ui/core';

import FullResume from './FullResume';
import { FileSelectButton } from '../components';
import PrintContext from '../components/PrintContext';


export default function ResumeGenerator() {
  const componentRef = useRef(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('/fullResumeData.json')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  const handleFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.files || evt.target.files.length === 0) return;

    const handleFileRead = () => {
      const content = fileReader.result;
      try {
        if (typeof content === 'string')
          setData(JSON.parse(content));
      }
      catch (err) {}
    }
    let fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(evt.target.files[0]);
  };

  const downloadData = () => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 4)));
    element.setAttribute('download', 'resumeData.json');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return(
    <div>
      <CssBaseline />
      <div style={{textAlign: 'center', marginTop: 10, marginBottom: 10}}>
        <FileSelectButton extension='.json' onChange={handleFileSelect} />
        <Button variant='contained' onClick={downloadData}>Download Current Data</Button>
        <Button variant='contained' href='/fullResumeTemplate.json' download='resumeDataTemplate.json'>Download Template</Button>
      </div>
      <div style={{textAlign: 'center', marginTop: 10, marginBottom: 10}}>
        
        <ReactToPrint
          trigger={() => <Button variant='contained'>PRINT OR SAVE AS PDF</Button>}
          //@ts-ignore
          content={() => componentRef.current}
        />
      </div>
      <Container maxWidth='md'>
        <Paper style={{paddingTop: 1}}>
          <PrintContext.Provider value={true}>
            <FullResume ref={componentRef} data={data} />
          </PrintContext.Provider>
        </Paper>
      </Container>
    </div>
  );
}
